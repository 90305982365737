<template>
  <div>
    <div class="page-body">
      <loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
      />
      <v-container>
        <v-card flat class="border mt-6" rounded="lg">
          <v-card-title>
            <span></span>
            <v-btn
              @click="openDialog()"
              class="elevation-1"
              elevation-1
              color="primary"
              small
              ><v-icon> </v-icon>Initiate Payment</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-dialog v-model="paymentdialog" max-width="700px">
            <v-card class="rounded-lg">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <div class="pt-3">
                    <p class="text-center slot-icon">
                      <i class="fa-solid fa-paper-plane fw-slot-icon"></i>
                    </p>
                    <p class="text-center font-weight-bold">
                      Initiate a Payout
                    </p>
                  </div>
                  <v-card-text>
                    <v-form v-model="valid" ref="busApplicant">
                      <v-col>
                        <label class="mt-0"
                          >Amount<span style="color: #f00">*</span></label
                        >
                        <v-text-field
                          v-model="amount"
                          :placeholder="$t('please_enter_amount')"
                          outlined
                          type="number"
                          hide-spin-buttons
                          required
                          dense
                          :rules="FieldRequired('Amount')"
                        >
                          <template v-slot:prepend-inner class="pt-0">
                            <span class="font-weight-bold mt-1 me-2">$</span>
                          </template></v-text-field
                        >
                      </v-col>
                      <v-col>
                        <label class="mt-0"
                          >Description<span style="color: #f00">*</span></label
                        >
                        <v-text-field
                          v-model="description"
                          :placeholder="$t('please_enter_description')"
                          outlined
                          type="text"
                          hide-spin-buttons
                          required
                          dense
                          :rules="[
                            ...FieldRequired('Description'),
                            ...descriptionVlaidation,
                          ]"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col class="mt-0">
                        <v-tabs horizontal dense v-model="selctedtab">
                          <v-tab dense>
                            <!-- <v-icon left> mdi-account-plus </v-icon> -->
                            New
                          </v-tab>
                          <v-tab dense>
                            <!-- <v-icon left> mdi-account </v-icon> -->
                            Existing
                          </v-tab>

                          <v-tab-item dense>
                            <v-card
                              v-if="selctedtab == '0'"
                              class="pa-3 elevation-0 border"
                            >
                              <v-row>
                                <v-col cols="12" md="6">
                                  <label class="mt-0"
                                    >{{ "Name"
                                    }}<span style="color: #f00">*</span></label
                                  >
                                  <v-text-field
                                    placeholder="Please Enter Name"
                                    outlined
                                    type="text"
                                    hide-spin-buttons
                                    required
                                    dense
                                    :rules="FieldRequired('Name')"
                                    v-model="add_Beneficiary.name"
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <label
                                    >{{ $t("emailID")
                                    }}<span style="color: #f00">*</span></label
                                  >
                                  <v-text-field
                                    :placeholder="$t('emailID')"
                                    outlined
                                    required
                                    dense
                                    :rules="[
                                      ...FieldRequired('Email Id'),
                                      ...emailValidations,
                                    ]"
                                    v-model="add_Beneficiary.email"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <label :description="$t('optional')">
                                    {{ $t("phoneNumber") }} (+61 04XX XXX XXX)<span style="color: #f00">*</span>
                                  </label>
                                  <v-text-field
                                    type="number"
                                    hide-spin-buttons
                                    :placeholder="
                                      $t('mobile_number_placeholder')
                                    "
                                    outlined
                                    required
                                    dense
                                    :rules="[
                                      ...FieldRequired('Mobile Number'),
                                      ...mobileRules,
                                    ]"
                                    v-model="add_Beneficiary.mobile"
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <label :description="$t('optional')">
                                    {{ $t("Address")
                                    }}<span style="color: #f00">*</span>
                                  </label>
                                  <v-text-field
                                    hide-spin-buttons
                                    placeholder="Please Enter Address"
                                    outlined
                                    required
                                    dense
                                    :rules="FieldRequired('Address')"
                                    v-model="add_Beneficiary.address"
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <label :description="$t('optional')">
                                    Payment Method<span style="color: #f00"
                                      >*</span
                                    >
                                  </label>
                                  <v-select
                                    v-model="add_Beneficiary.payee_type"
                                    :menu-props="{
                                      bottom: true,
                                      offsetY: true,
                                    }"
                                    :items="payidTypes"
                                    placeholder="Select Payid Type"
                                    outlined
                                    type="number"
                                    :rules="FieldRequired('Payment Method')"
                                    hide-spin-buttons
                                    required
                                    dense
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <label class="mt-0"
                                    >{{
                                      add_Beneficiary.payee_type === "BSB"
                                        ? "Account Number"
                                        : "Pay Id"
                                    }}<span style="color: #f00">*</span></label
                                  >
                                  <div class="d-flex">
                                    <v-text-field
                                      v-model="add_Beneficiary.account_number"
                                      placeholder="Please Enter Value"
                                      outlined
                                      :type="
                                        add_Beneficiary.payee_type === 'PPHONE'
                                          ? 'number'
                                          : ''
                                      "
                                      hide-spin-buttons
                                      :rules="
                                        returnValidation(
                                          add_Beneficiary.payee_type
                                        )
                                      "
                                      required
                                      dense
                                    ></v-text-field>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                  v-if="add_Beneficiary.payee_type === 'BSB'"
                                >
                                  <label class="mt-0"
                                    >BSB Number<span style="color: #f00"
                                      >*</span
                                    ></label
                                  >
                                  <div class="d-flex">
                                    <v-text-field
                                      v-model="add_Beneficiary.bsb"
                                      placeholder="Please Enter BSB Number"
                                      outlined
                                      type="number"
                                      hide-spin-buttons
                                      :rules="FieldRequired('BSB Number')"
                                      required
                                      dense
                                    ></v-text-field>
                                  </div>
                                </v-col> </v-row></v-card
                          ></v-tab-item>
                          <v-tab-item
                            ><v-card
                              flat
                              class="mx-auto list-payout"
                              height="250px"
                            >
                              <label class="ms-4 ma-0 pa-0"
                                >Please select Payout.</label
                              >
                              <p
                                v-if="errorMessage"
                                class="error-message red--text"
                              >
                                {{ errorMessage }}
                              </p>
                              <v-list two-line dense>
                                <v-list-item-group
                                  v-model="selected"
                                  active-class="primary-text"
                                >
                                  <template>
                                    <v-list-item
                                      v-for="(item, index) in payouts"
                                      :key="index"
                                      @click="selectUser(item)"
                                    >
                                      <template v-slot:default="{ active }">
                                        <v-list-item-avatar
                                          size="30"
                                          class="rounded-circle me-3 font-weight-bold"
                                          :color="getAvatarColor()"
                                          dark
                                        >
                                          {{ returnAvatar(item.first_name) }}
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            ><span></span>
                                            {{
                                              returnFullName(item)
                                            }}</v-list-item-title
                                          >
                                          <v-list-item-subtitle>
                                            <p class="mb-0">
                                              Pay Id:
                                              <span class="font-weight-bold">{{
                                                item.bank_account_no
                                              }}</span>
                                            </p>
                                            <p class="mb-0">
                                              Email: {{ item.email_id }} Phone
                                              Number:{{ item.phone_number }}
                                            </p>
                                          </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                          <v-icon
                                            medium
                                            v-if="active"
                                            color="green darken-3"
                                          >
                                            mdi-check-circle
                                          </v-icon>
                                        </v-list-item-action>
                                      </template>
                                    </v-list-item>

                                    <v-divider
                                      class="mt-0"
                                      v-if="index < payouts.length - 1"
                                      :key="index"
                                    ></v-divider>
                                  </template>
                                </v-list-item-group>
                              </v-list> </v-card
                          ></v-tab-item>
                        </v-tabs>
                      </v-col>
                    </v-form>
                  </v-card-text>
                  <v-divider class="p-o m-0"></v-divider>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="paymentdialog = false"
                      outlined
                    >
                      {{ $t("cancel_label") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="navigateNextPage()"
                      class="elevation-0"
                      color="primary"
                      >{{ $t("next") }}</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text>
                    <div class="mb-4">
                      <p class="text-center check-icon-success mb-3">
                        <i class="fa fa-info fw-check-success"></i>
                      </p>
                      <p class="text-center font-weight-bold">
                        Your Payment is about to proceed.
                      </p>
                      <p class="ms-6 mb-0 text-center">
                        Before proceeding to payment, kindly review the
                        following details.If all the information is correct,
                        please proceed to payment.
                      </p>
                    </div>
                    <p class="font-weight-bold">Payment Information:</p>
                    <paymentSummary
                      :selectedItem="selectedItem"
                      :amount="amount"
                    />
                  </v-card-text>
                  <v-divider class="p-o m-0"></v-divider>
                  <v-card-actions>
                    <v-btn color="primary" outlined @click="step--">
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="verifyPay()"
                      class="elevation-0"
                      color="primary"
                      >{{ "Pay" }}</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
              </v-window>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headers"
            :items="paymentsSummary"
            class="elevation-0"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="item.status"
                class="text-capitalize"
                small
                :color="getColor(item.status)"
                dark
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.email="{ item }">
              <p class="mb-0">
                <span> Email:{{ item.email }} </span><br />
                <span>Phone:{{ item.mobile }}</span>
              </p>
            </template>
            <template v-slot:item.payee_type="{ item }">
              <p v-if="item.payee_type == 'BSB'">
                BSB:{{ item.bsb }}
                <br />
                Account No: {{ item.account_number }}
              </p>
              <p v-else>
                {{ item.payee_type }}<span>: {{ item.account_number }}</span>
              </p>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                title="View & Download Reciept"
                color="primary"
                @click="download(item)"
                >mdi-download</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
        <v-dialog v-model="showPaymentDialog" width="700px">
          <paymentReciept :selectedData="selectedData" />
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>
<script>
import alerts from "@/mixins/alerts";
import Loading from "vue-loading-overlay";
import remitteService from "../../services/RemitteService/remitteService.js";
import transactionService from "../../services/TransactionService/transactionService.js";
import { FieldRequired } from "@/utils/validation.js";
import paymentReciept from "./paymentReciept.vue";
import paymentSummary from "./paymentSummary.vue";
import { validations } from "../../utils/validation.js";
import commonService from "../../services/CommonService/commonService.js";
const { emailValidations, mobileRules, descriptionVlaidation } = validations;
export default {
  mixins: [alerts],
  data() {
    return {
      selctedtab: "",
      emailValidations,
      descriptionVlaidation,
      mobileRules,
      loader: false,
      FieldRequired,
      selected: [],
      payidTypes: [],
      amount: "",
      description: "",
      selectedItem: {},
      receiver_id: "",
      step: 1,
      resultPayid: "",
      paymentdialog: false,
      successdialog: false,
      showPaymentDialog: false,
      valid: true,
      search: "",
      selectedData: {},
      payouts: [],
      errorMessage: "",
      add_Beneficiary: {
        name: "",
        email: "",
        mobile: "",
        payee_type: "PEMAIL",
        account_number: "",
        address: "",
        amount: "",
        bsb: "",
      },
      headers: [
        { text: "Transaction pin", value: "transaction_pin" },
        { text: "Amount($)", value: "amount" },
        { text: "Payee Name", value: "name" },
        { text: "Status", value: "status" },
        { text: "Payment Method", value: "payee_type" },
        { text: "Description", value: "description" },
        { text: "Date", value: "created_date" },
        { text: "Actions", value: "actions" },
      ],
      paymentsSummary: [],
    };
  },

  watch: {
    selctedtab(val) {
      if (val) {
        this.selectedItem = {};
        this.selected = [];
        this.receiver_id = null;
        this.add_Beneficiary = {
          name: "",
          email: "",
          mobile: "",
          payee_type: "PEMAIL",
          account_number: "",
          address: "",
          amount: "",
          bsb: "",
        };
      }
    },
    selected(val) {
      const temp = { ...this.items[val] };
      this.selectedItem = {
        name:
          temp.first_name +
          (temp.middle_name ? " " + temp.middle_name : "") +
          " " +
          temp.last_name,
        email: temp.email_id,
        mobile: temp.phone_number,
        payee_type: temp.payment_code,
        account_number: temp.bank_account_no,
        address: temp.address,
        bsb: temp.branch_code,
      };
      console.log("test", this.selectedItem);
    },
  },
  components: {
    paymentReciept,
    paymentSummary,
    Loading,
  },
  mounted() {
    this.getPayoutSummary();
    this.getpayidMethods();
  },
  methods: {
    returnValidation(val) {
      if (val === "PEMAIL") {
        return [...this.FieldRequired("value"), ...this.emailValidations];
      } else if (val === "PPHONE") {
        return [...this.FieldRequired("value"), ...this.mobileRules];
      } else {
        return [...this.FieldRequired("value")];
      }
    },
    navigateNextPage() {
      if (this.$refs.busApplicant.validate()) {
        if (!this.receiver_id && this.selctedtab == "1") {
          this.errorMessage = "Select the one of the payout in the below.";
        } else if (
          this.selctedtab == "0" &&
          this.add_Beneficiary.account_number
        ) {
          this.selectedItem = { ...this.add_Beneficiary };
          console.log("this.selectedItem ", this.selectedItem);
          this.errorMessage = "";
          this.step++;
        } else if (this.receiver_id && this.selctedtab == "1") {
          this.errorMessage = "";
          this.step++;
        }
      }
    },
    verifyPay() {
      if (this.selctedtab == "1" && this.receiver_id) {
        this.payoutBeneficiary();
      } else if (
        this.selctedtab == "0" &&
        this.add_Beneficiary.account_number
      ) {
        this.initiateNewPyout();
      }
    },
    async getpayidMethods() {
      try {
        const response = await commonService.getParamterDropdown("DPTY");
        console.log("getpayidMethods", response.data);
        if (response.data.status_code === 200 && response.data) {
          this.payidTypes = response.data.data.map((ele) => {
            return {
              text: ele.value,
              value: ele.value_code,
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectUser(item) {
      console.log("selectUser", item);
      if (item.uuid != this.selectedItem.uuid) {
        this.selectedItem = {
          name:
            item.first_name +
            (item.middle_name ? " " + item.middle_name : "") +
            " " +
            item.last_name,
          email: item.email_id,
          mobile: item.phone_number,
          payee_type: item.payment_code,
          account_number: item.bank_account_no,
          address: item.address,
          bsb: item.branch_code,
        };
        this.receiver_id = item.uuid;
        console.log(this.receiver_id);
      } else {
        this.selectedItem = {};
        this.receiver_id = null;
      }
    },
    openDialog() {
      this.getPayouts();
      this.paymentdialog = true;
      this.$refs.busApplicant.reset();
      this.selectedItem = {};
      this.receiver_id = null;
    },
    download(item) {
      this.showPaymentDialog = true;
      this.selectedData = item;
      console.log(item);
    },
    opensuceesDialog() {
      this.paymentdialog = false;
      this.successdialog = true;
    },
    returnFullName(item) {
      if (item.middle_name) {
        return item.first_name + " " + item.middle_name + " " + item.last_name;
      } else {
        return item.first_name + " " + item.last_name;
      }
    },
    async getPayouts() {
      const params = {
        skip: 0,
        limit: 30000,
      };
      try {
        this.loader = true;
        const response = await remitteService.getBeneficiary(params);
        console.log("response", response.data.message);
        if (response.data.status_code === 200 && response.data) {
          this.loader = false;
          this.payouts = response.data.data;
          console.log(this.payouts);
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.beneficiary = [];
        console.log(error);
      }
    },
    async initiateNewPyout() {
      this.add_Beneficiary.amount = this.amount;
      const data = [this.add_Beneficiary];
      try {
        this.loader = true;
        const response = await transactionService.payoutinitiate(
          this.description,
          data
        );
        if (response.data && response.data.status_code === 200) {
          this.loader = false;
          console.log(response);
          this.paymentdialog = false;
          this.showAlertSuccess(response.data.message);
        } else {
          this.showAlertError(response.data.message);
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async payoutBeneficiary() {
      console.log(this.receiver_id);

      const data = [
        {
          receiver_id: this.receiver_id,
          amount: this.amount,
        },
      ];
      try {
        this.loader = true;
        const response = await transactionService.payoutBeneficiary(
          this.description,
          data
        );
        if (response.data && response.data.status_code === 200) {
          this.loader = false;
          console.log(response);
          this.paymentdialog = false;
          this.showAlertSuccess(response.data.message);
        } else {
          this.showAlertError(response.data.message);
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async getPayoutSummary() {
      const params = {
        skip: 0,
        limit: 2000000,
      };
      try {
        this.loader = true;
        const response = await transactionService.getPayoutSummary(params);
        if (response.data.status_code == 200) {
          this.loader = false;
          this.paymentsSummary = response.data.data;
        } else {
          this.loader = false;
        }
        console.log(response.data.data);
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    copyPayId() {
      navigator.clipboard.writeText(this.resultPayid);
      this.showAlertSuccess("Pay id copied successfully.");
    },
    returnAvatar(name) {
      const words = name.split(" ");
      // Map each word to its first character and join them
      const initials = words.map((word) => word.charAt(0)).join("");
      // Return the initials in uppercase
      return initials[0].toUpperCase();
    },
    getAvatarColor() {
      // Generate random values for RGB, ensuring they are between 127 and 255
      const r = Math.floor(Math.random() * 128) + 127;
      const g = Math.floor(Math.random() * 128) + 127;
      const b = Math.floor(Math.random() * 128) + 127;

      // Convert the RGB values to a hexadecimal string
      const hexColor = `#${r.toString(16).padStart(2, "0")}${g
        .toString(16)
        .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

      return hexColor.toUpperCase();
    },
    getColor(status) {
      if (status == "settled") return "green";
      else if (status == "in-process") return "orange";
      else if (status == "processing") return "yellow";
      else if (status == "failed") return "red";
      else return "blue";
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slot-icon {
  padding-top: 3px;
  font-size: 40px;
  border: 10px solid #ffe7d6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: #fdd1b2;
  margin: auto;
}

.fw-slot-icon {
  color: white;
}
.check-icon-success {
  padding-top: 10px;
  font-size: 35px;
  font-weight: 800;
  border: 12px solid rgb(252, 252, 167);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: rgb(196, 196, 50);
  margin: auto;
}
.list-payout {
  overflow-y: scroll;
}
.fw-check-success {
  color: white;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    margin-bottom: 0px !important;
  }
}
</style>
