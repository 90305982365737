<template>
  <v-card flat class="" rounded="lg">
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
    <div class="d-flex px-3">
      <div>
        <v-select
          v-model="recordStatus"
          label="Select Record Status"
          :items="recordStatusVal"
          outlined
          dense
          hide-details
        ></v-select>
      </div>

      <v-btn
        @click="getPayoutSummary()"
        class="elevation-0 mt-1 ms-2"
        elevation-1
        color="primary"
        small
        >Get Results</v-btn
      >
    </div>
    <v-data-table
      :headers="headers"
      :items="paymentsSummary"
      class="elevation-0"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item.status"
          small
          :color="item.status == 'F' ? 'red' : 'success'"
          dark
        >
          {{ item.status == "F" ? "Failed" : "Success" }}
        </v-chip>
      </template>
      <template v-slot:item.payee_type="{ item }">
        <v-chip v-if="item.payee_type" small>{{ item.payee_type }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import alerts from "@/mixins/alerts";
import Loading from "vue-loading-overlay";
import transactionService from "@/services/TransactionService/transactionService.js";
export default {
  mixins: [alerts],
  props: ["selectedFileID"],
  data() {
    return {
      selctedtab: "",
      loader: false,
      recordStatus: "S",
      recordStatusVal: [
        {
          text: "Total",
          value: "T",
        },
        {
          text: "Success",
          value: "S",
        },
        {
          text: "Failed",
          value: "F",
        },
      ],
      resultPayid: "",
      paymentdialog: false,
      showPaymentDialog: false,
      valid: true,
      search: "",
      headers: [
        { text: "Payee Name", value: "name" },
        { text: "Amount($)", value: "amount" },
        { text: "Status", value: "status" },
        { text: "Payment Method", value: "payee_type" },
        { text: "Email", value: "email" },
        { text: "Mobile", value: "mobile" },
        { text: "BSB", value: "bsb" },
        { text: "Account No", value: "account_number" },
        { text: "Date", value: "created_date" },
      ],
      paymentsSummary: [],
    };
  },

  components: {
    Loading,
  },

  methods: {
    async getPayoutSummary() {
      if (this.recordStatus) {
        const params = {
          document_id: this.selectedFileID,
          status: this.recordStatus == "T" ? null : this.recordStatus,
        };
        try {
          this.loader = true;
          const response = await transactionService.getRecordPayoutSummary(
            params
          );
          if (response.data.status_code == 200) {
            this.loader = false;
            this.paymentsSummary = response.data.data.reverse();
          } else {
            this.loader = false;
          }
          console.log(response.data.data);
        } catch (error) {
          this.loader = false;
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slot-icon {
  padding-top: 3px;
  font-size: 40px;
  border: 10px solid #ffe7d6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: #fdd1b2;
  margin: auto;
}

.fw-slot-icon {
  color: white;
}
.check-icon-success {
  padding-top: 10px;
  font-size: 35px;
  font-weight: 800;
  border: 12px solid rgb(252, 252, 167);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: rgb(196, 196, 50);
  margin: auto;
}
.list-payout {
  overflow-y: scroll;
}
.fw-check-success {
  color: white;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    margin-bottom: 0px !important;
  }
}
</style>
