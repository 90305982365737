<template>
  <section>
    <v-btn @click="generateReport" class="ms-4" depressed color="primary"
      ><v-icon>mdi-printer </v-icon>
      <span class="ms-2 ">Print Reciept</span></v-btn
    >
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="2800"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="landscape"
      pdf-content-width="110"
      ref="html2Pdf"
      @hasDownloaded="handleDownloaded"
    >
      <section slot="pdf-content" class="content">
        <slot></slot>
      </section>
    </VueHtml2pdf>
  </section>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    VueHtml2pdf,
  },
  mounted() {
    console.log(this.$store.state);
  },
  computed: {
    ...mapState(["selectedExportComp"]),
    selectedExportComp() {
      alert(this.$store.state.selectedExportComp);
      return this.$store.state.selectedExportComp;
    },
  },
  props: {
    fileName: {
      type: String,
    },
  },
  methods: {
    handleDownloaded() {
      this.$emit("hasDownloaded");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
<style>
.content {
  margin: 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
</style>
