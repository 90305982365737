<template>
  <section>
    <html2Pdf ref="vuepdf">
      <v-card class="padd-5" width="750px">
        <v-row>
          <v-col class="d-flex Justify-center" cols="4">
            <v-card width="auto" flat>
              <img
                src="@/assets/master_logo.png"
                width="170"
                height="80"
                contain
                class="mt-3 ms-5"
              />
            </v-card>
          </v-col>
          <v-col class="ma-3">
            <p class="mb-0 pb-0">
              <b> {{ $t("qqpay_sdn_bhd") }}</b>
            </p>
            <p class="mb-0 mt-0 address-font">
              <br />

              {{ $t("company_address") }}
              {{ $t("company_sub_address") }}
            </p>
          </v-col>
        </v-row>
        <v-card
          outlined
          tile
          height="30px"
          class="mt-5 border-rounded title-card-bg-color"
        >
          <div class="white--text text-center text-h6">
            {{ $t("reciept_label") }}
          </div>
        </v-card>
        <v-card
          outlined
          tile
          class="mt-1 padd-3 border-rounded font-text card-bg-color"
        >
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("transcation_id_receipt_no") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> {{ selectedData.transaction_pin }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("date") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> {{ selectedData.created_date }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("send_amount") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> $ {{ selectedData.amount }}
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          tile
          class="mt-1 padd-3 border-rounded font-text card-bg-color"
        >
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("sender_name") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span>{{ selectedData.customer_name }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("contact_number") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span>{{ selectedData.customer_phone }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("address_beneficary") }}
            </v-col>
            <v-col cols="7" class="d-flex">
              <span class="paddleft-2">:</span>
              <div>
                {{ selectedData.customer_address }}
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          tile
          class="mt-1 padd-3 border-rounded font-text card-bg-color"
        >
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("Beneficiary Name") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> {{ selectedData.name }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("Address") }}
            </v-col>
            <v-col cols="7" class="d-flex">
              <span class="paddleft-2">:</span>
              <div class="ms-1">
                {{ selectedData.address }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("Email") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> {{ selectedData.email }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("Mobile") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> {{ selectedData.mobile }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" class="ps-2">
              {{ $t("Pay Id") }}
            </v-col>
            <v-col cols="7">
              <span class="paddleft-2">:</span> {{ selectedData.account_number }}
            </v-col>
          </v-row>
        </v-card>
        <v-col class="address-font">
          <div class="ps-2 pt-2 pb-0 text-grey">
            {{ $t("senders_responsibility_to_notify_the_beneficary") }}
          </div>
          <div class="ps-2 pt-2 pb-0 text-grey">
            {{ $t("sender's responsibility to provide accurate information") }}
          </div>
          <div class="ps-2 pt-2 pb-0 text-grey">
            {{ $t("computer_generated_receipt") }}
          </div>
        </v-col>
      </v-card>
    </html2Pdf>
  </section>
</template>

<script>
import html2Pdf from "../TransactionSummary/vue-html-pdf.vue";
export default {
  data() {
    return {};
  },
  components: {
    html2Pdf,
  },
  props: {
    selectedData: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.font-text {
  font-size: 15px !important;
  line-height: 26px;
}
.address-font {
  font-size: 13px;
}
.paddleft-2 {
  padding-right: 10px;
}
.text-grey {
  color: grey;
}
.card-bg-color {
  background-color: rgb(245, 245, 245) !important;
  border-radius: 4px !important;
  border: none;
}
.title-card-bg-color {
  background-color: #ff6b00 !important;
  border-radius: 4px !important;
}
.padd-3 {
  padding: 10px;
}
.padd-5 {
  padding: 15px;
}
</style>
