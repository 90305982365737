<template>
  <div>
    <v-simple-table class="border" dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Total Records</td>
            <td>
              <v-chip small color="primary" class="text-white font-weight-bold">
                {{ fileResultData.total_records }}
                <v-icon small class="ms-3">mdi-upload-circle</v-icon>
              </v-chip>
            </td>
          </tr>

          <tr>
            <td>Success Records</td>
            <td>
              <v-chip small color="success" class="text-white font-weight-bold">
                {{ fileResultData.success_records }}
                <v-icon small class="ms-3">mdi-check-circle</v-icon>
              </v-chip>
            </td>
          </tr>
          <tr>
            <td>Failed Records</td>
            <td>
              <v-chip small color="red" class="text-white font-weight-bold">
                {{ fileResultData.failure_records }}
                <v-icon small class="ms-3">mdi-close-circle</v-icon>
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "Payins",
  props: {
    fileResultData: {
      type: Object,
    },
  },
  data() {},

  components: {},
  created() {},
  monted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
</style>
