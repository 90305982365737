<template>
  <div>
    <div class="page-body">
      <TitleBar :title="$t('Payout')"  />
      <v-container>
        <v-card flat class="border mt-6" rounded="lg">
          <v-tabs horizontal>
            <v-tab class="text-capitalize">
              <v-icon left> mdi-file </v-icon>
              Transaction History
            </v-tab>
            <v-tab class="text-capitalize">
              <v-icon left> mdi-account </v-icon>
              Payout List
            </v-tab>
            <v-tab class="text-capitalize">
              <v-icon left> mdi-upload </v-icon>
              Batch Payment
            </v-tab>

            <v-tab-item><PayinsHistory /> </v-tab-item>
            <v-tab-item><AddPayins /> </v-tab-item>
            <v-tab-item><bulkUpload /> </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>
    </div>
  </div>
</template>
<script>
import AddPayins from "./Addpayout.vue";
import PayinsHistory from "./PayoutHistory.vue";
import bulkUpload from "./bulkUpload.vue"
import TitleBar from "@/components/navigations/TitleBar.vue";
export default {
  name: "Payins main",
  data() {
    return {};
  },
  components: {
    AddPayins,
    PayinsHistory,
    bulkUpload,
    TitleBar
  },
  created() {},
  monted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
</style>
