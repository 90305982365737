<template>
  <div>
    <v-simple-table class="border">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Amount</td>
            <td>$ {{ amount }}</td>
          </tr>
          <!-- <tr>
            <td>Fee</td>
            <td>$ {{ "2.02" }}</td>
          </tr> -->
          <tr>
            <td>Name</td>
            <td>
              {{ selectedItem.name }}
             
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ selectedItem.email }}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{{ selectedItem.mobile }}</td>
          </tr>
          <tr>
            <td>Pay Id</td>
            <td>
              {{ selectedItem.account_number }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  name: "Payins",
  data() {},
  props: {
    selectedItem: {
      type: Object,
    },
    amount: {
      type: String,
    },
  },
  components: {},
  created() {},
  monted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
</style>
