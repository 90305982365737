<template>
  <div>
    <div class="page-body">
      <loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
      />
      <v-container>
        <v-card flat class="border mt-6" rounded="lg">
          <v-card-title>
            <span></span>
            <v-btn
              @click="openDialog()"
              class="elevation-1"
              elevation-1
              color="primary"
              small
              ><v-icon> </v-icon> Upload</v-btn
            >
            <a href="/Payout-API-Request.xlsx" class="text-subtitle-1" download>
              <v-icon color="primary">mdi-download </v-icon>Sample Excel File
              <v-icon color="primary" small>mdi-file-excel</v-icon>
            </a>
            <!-- <v-btn
              @click="downloadSample()"
              class="elevation-0 ms-2 text-capitalize"
              text
              elevation-1
              color="primary"
              small
            ></v-btn> -->
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-dialog v-model="paymentdialog" max-width="900px">
            <v-card class="rounded-lg">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <div class="pt-3">
                    <p class="text-center slot-icon">
                      <i class="fa-solid fa-upload fw-slot-icon"></i>
                    </p>
                    <p class="mb-0 text-center font-weight-bold">
                      Initiate Bulk Payments.
                    </p>
                    <p class="text-center px-2">
                      Please upload and click on verify to verify your file and
                      proceed to payment.
                    </p>
                  </div>
                  <v-card-text>
                    <v-form v-model="valid" ref="busApplicant">
                      <v-row class="d-flex align-center">
                        <v-col cols="12" md="12">
                          <label class=""
                            >Upload File<span style="color: #f00"
                              >*</span
                            ></label
                          >
                          <v-file-input
                            v-model="bulk_file"
                            accept=".xlsx"
                            @change="verifyFile()"
                            placeholder="Browse File"
                            outlined
                            required
                            dense
                            :rules="FieldRequired('File')"
                          >
                          </v-file-input>
                        </v-col>
                        <!-- <v-col cols="12" md="2" class="mb-2">
                          <v-btn
                            small
                            @click="verifyFile()"
                            color="primary"
                            class="elevation-0"
                          >
                            verify
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-form>
                    <p
                      v-if="bulk_file && bulk_file.name"
                      class="mb-0 font-weight-bold"
                    >
                      Uploaded File Info:
                    </p>
                    <v-card
                      v-if="bulk_file && bulk_file.name"
                      class="elevation-0 border"
                    >
                      <v-card-text class="pa-1 ps-3">
                        <div class="d-flex align-center">
                          <div class="">
                            <p class="mb-0">
                              Name:
                              <span class="font-weight-bold">{{
                                bulk_file && bulk_file.name
                                  ? bulk_file.name
                                  : ""
                              }}</span>
                            </p>
                            <p class="mb-0">
                              Size:
                              <span class="font-weight-bold"
                                >{{
                                  bulk_file && bulk_file.size
                                    ? bulk_file.size
                                    : ""
                                }}
                                kb</span
                              >
                            </p>
                            <p class="mb-0">
                              Type:
                              <span class="font-weight-bold">{{
                                bulk_file && bulk_file.type
                                  ? bulk_file.type
                                  : ""
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                    <p
                      v-if="bulk_file && bulk_file.name"
                      class="mb-0 mt-2 font-weight-bold"
                    >
                      File Verification Results:
                    </p>
                    <v-card
                      v-if="bulk_file && bulk_file.name"
                      class="elevation-0"
                    >
                      <!------------------------ validate bulk data -------------------------->
                      <validateBulk
                        :fileValidateResultData="fileValidateResultData"
                      />
                    </v-card>
                  </v-card-text>
                  <v-divider class="p-o m-0"></v-divider>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="paymentdialog = false"
                      outlined
                    >
                      {{ $t("cancel_label") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="navigateNextPage()"
                      class="elevation-0"
                      color="primary"
                      >Proceed to payment</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
                <v-window-item :value="2">
                  <v-card-text>
                    <div class="mb-4">
                      <p class="text-center check-icon-success mb-3">
                        <v-icon color="white" x-large>mdi-file-excel</v-icon>
                      </p>
                      <p class="text-center font-weight-bold">
                        Your File is uploaded and verifed.
                      </p>
                      <p class="ms-6 mb-0 text-center">
                        Please find the results of the uploaded Payment file
                        below.
                      </p>
                    </div>

                    <p class="font-weight-bold mt-2 mb-1">File Results:</p>
                    <UploadSummary :fileResultData="fileResultData" />
                  </v-card-text>
                  <v-divider class="p-o m-0"></v-divider>
                  <v-card-actions>
                    <!-- <v-btn color="primary" outlined @click="step--">
                      {{ $t("back") }}
                    </v-btn> -->
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeVerification()"
                      class="elevation-0"
                      color="primary"
                      >ok</v-btn
                    >
                  </v-card-actions>
                </v-window-item>
              </v-window>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headers"
            :items="paymentsSummary"
            class="elevation-0"
          >
            <template v-slot:item.total_records="{ item }">
              <v-chip
                class="font-weight-bold"
                outlined
                v-if="item.total_records"
                small
                color="primary"
                dark
              >
                {{ item.total_records
                }}<v-icon small class="ms-3">mdi-upload-circle</v-icon>
              </v-chip>
            </template>
            <template v-slot:item.no_of_success="{ item }">
              <v-chip
                class="font-weight-bold"
                outlined
                v-if="item.no_of_success"
                small
                color="success"
                dark
              >
                {{ item.no_of_success }}
                <v-icon small class="ms-3">mdi-check-circle</v-icon>
              </v-chip>
            </template>
            <template v-slot:item.no_of_failure="{ item }">
              <v-chip class="font-weight-bold" outlined small color="red" dark>
                {{ item.no_of_failure
                }}<v-icon small class="ms-3">mdi-close-circle</v-icon>
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                title="Download File"
                @click="openFileResults(item)"
                color="primary"
                class="elevation-0"
                small
              >
                <v-icon small class="me-1">mdi-eye </v-icon>view
              </v-btn>
            </template>
            <!-- <template v-slot:item.document_path="{ item }">
              <v-btn
                title="Download File"
                @click="downloadDoc(item)"
                color="primary"
                icon
                fab
                small
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card>
        <v-dialog v-model="showFileResults" width="1200px">
          <v-card>
            <div class="d-flex justify-space-between pa-2">
              <div class="d-flex ms-2">
                File:
                <p class="mb-0 font-weight-bold">Payout-API-Request-(1).xlsx</p>
              </div>
              <div>
                <v-btn icon fab small>
                  <v-icon large @click="closeResultDig()"
                    >mdi-close-circle</v-icon
                  >
                </v-btn>
              </div>
            </div>
            <v-card-text>
              <template v-if="showFileResults">
                <fileResults :selectedFileID="selectedFileID" />
              </template>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showPaymentDialog" width="700px">
          <fileResults />
          <paymentReciept :selectedData="selectedData" />
        </v-dialog>
      </v-container>
    </div>
  </div>
</template>
<script>
import alerts from "@/mixins/alerts";
import Loading from "vue-loading-overlay";
import transactionService from "../../services/TransactionService/transactionService.js";
import { FieldRequired } from "@/utils/validation.js";
import paymentReciept from "./paymentReciept.vue";
import UploadSummary from "./BulkUpload/uploadSummary.vue";
import fileResults from "./BulkUpload/fileResults.vue";
import validateBulk from "./BulkUpload/validateBulk.vue";
export default {
  mixins: [alerts],
  data() {
    return {
      selctedtab: "",
      loader: false,
      FieldRequired,
      bulk_file: null,
      step: 1,
      resultPayid: "",
      selectedFileID: null,
      paymentdialog: false,
      successdialog: false,
      showPaymentDialog: false,
      valid: true,
      search: "",
      errorMessage: "",
      fileResultData: {},
      fileValidateResultData: {},
      showFileResults: false,
      headers: [
        { text: "File Name", value: "document_name" },
        { text: "Uploaded Date", value: "uploaded_date" },
        { text: "Total", value: "total_records" },
        { text: "Success", value: "no_of_success" },
        { text: "Failed", value: "no_of_failure" },
        { text: "Actions", value: "actions" },
        // { text: "Download", value: "document_path" },
      ],
      paymentsSummary: [],
    };
  },

  watch: {
    bulk_file(val) {
      console.log(val);
    },
  },
  components: {
    paymentReciept,
    UploadSummary,
    fileResults,
    validateBulk,
    Loading,
  },
  mounted() {
    this.getBulkSummary();
  },
  methods: {
    // downloadDoc(doc) {
    //   this.loader = true;
    //   const url = doc.document_path;
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute("download", doc.document_name);
    //   document.body.appendChild(link);
    //   link.click();
    //   this.loader = false;
    // },
    closeResultDig() {
      this.showFileResults = false;
    },
    openFileResults(item) {
      console.log(item);
      this.selectedFileID = item.id;
      this.showFileResults = true;
    },
    async verifyFile() {
      if (this.$refs.busApplicant.validate()) {
        const fileData = new FormData();
        fileData.append("document", this.bulk_file);
        try {
          this.loader = true;
          const response = await transactionService.VerifyPaymentFile(fileData);
          if (response.data && response.data.status_code === 200) {
            this.loader = false;
            this.fileValidateResultData = response.data.data[0];
            this.showAlertSuccess(response.data.message);
          } else {
            this.showAlertError(response.data.message);
            this.loader = false;
          }
        } catch (error) {
          this.loader = false;
          console.log(error);
        }
      }
    },
    async navigateNextPage() {
      if (this.$refs.busApplicant.validate()) {
        const fileData = new FormData();
        fileData.append("document", this.bulk_file);
        try {
          this.loader = true;
          const response = await transactionService.uploadPaymentFile(fileData);
          if (response.data && response.data.status_code === 200) {
            this.loader = false;
            this.fileResultData = response.data.data[0];
            this.step++;
            this.getBulkSummary();
            this.showAlertSuccess(response.data.message);
          } else {
            this.showAlertError(response.data.message);
            this.loader = false;
          }
        } catch (error) {
          this.loader = false;
          console.log(error);
        }
      }
    },

    openDialog() {
      this.paymentdialog = true;
      this.$refs.busApplicant.reset();
    },
    closeVerification() {
      this.paymentdialog = false;
      this.$refs.busApplicant.reset();
      this.fileResultData = {};
      this.step = 1;
    },
    download(item) {
      this.showPaymentDialog = true;
      this.selectedData = item;
      console.log(item);
    },
    opensuceesDialog() {
      this.paymentdialog = false;
      this.successdialog = true;
    },
    returnFullName(item) {
      if (item.middle_name) {
        return item.first_name + " " + item.middle_name + " " + item.last_name;
      } else {
        return item.first_name + " " + item.last_name;
      }
    },
    async getBulkSummary() {
      try {
        this.loader = true;
        const response = await transactionService.getBulkSummary();
        if (response.data.status_code == 200) {
          this.loader = false;
          this.paymentsSummary = response.data.data;
        } else {
          this.showAlertError(response.data.message);
          this.loader = false;
        }
        console.log(response.data.data);
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slot-icon {
  padding-top: 3px;
  font-size: 40px;
  border: 10px solid #ffe7d6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: #ff9d57;
  margin: auto;
}

.fw-slot-icon {
  color: white;
}
.check-icon-success {
  padding-top: 10px;
  font-size: 35px;
  font-weight: 800;
  border: 12px solid rgb(252, 252, 167);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: rgb(196, 196, 50);
  margin: auto;
}
.list-payout {
  overflow-y: scroll;
}
.fw-check-success {
  color: white;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    margin-bottom: 0px !important;
  }
}
</style>
