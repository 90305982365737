<template>
  <v-card class="elevation-0" rounded="lg">
    <v-card-text class="pa-0 ps-0 elevation-0">
      <v-expansion-panels variant="accordion">
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header
            color="#d2f8d2"
            class="font-weight-bold elevation-0"
          >
            <p class="mb-0">
              Success Records: {{ fileValidateResultData.success_records
              }}<v-icon small class="ms-1">mdi-check-circle</v-icon>
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="elevation-0">
            <v-btn
              @click="downloadExcelsheet('S')"
              outlined
              color="primary"
              class="elevation-0 mt-2"
              small
            >
              Export <v-icon small>mdi-download</v-icon>
            </v-btn>
            <v-data-table
              :headers="headers"
              dense
              :items="successRecords"
              class="elevation-0 mt-2 pa-0 mx-0"
            >
              <template v-slot:item.bsb="{ item }">
                <p class="mb-0" v-if="item.bsb && item.account_number">
                  <span> BSB:{{ item.bsb }} </span><br />
                  <span>Account No:{{ item.account_number }}</span>
                </p>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header
            color="red lighten-4"
            class="font-weight-bold"
          >
            <p class="mb-0">
              Failure Records:
              {{ fileValidateResultData.failure_records
              }}<v-icon small class="ms-1">mdi-close-circle</v-icon>
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn
              @click="downloadExcelsheet('F')"
              outlined
              color="primary"
              class="elevation-0 mt-2"
              small
            >
              Export <v-icon small>mdi-download</v-icon>
            </v-btn>

            <v-data-table
              :headers="headers"
              :items="failureRecords"
              class="elevation-0 mt-2"
            >
              <template v-slot:item.bsb="{ item }">
                <p class="mb-0" v-if="item.bsb && item.account_number">
                  <span> BSB:{{ item.bsb }} </span><br />
                  <span>Account No:{{ item.account_number }}</span>
                </p>
              </template>
              <template v-slot:item.description="{ item }">
                <p class="mb-0 red--text">
                  {{ item.description }}
                </p>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    fileValidateResultData: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        { text: "Payee Name", value: "name" },
        { text: "Amount($)", value: "amount" },
        { text: "Payment Method", value: "payid_type" },
        { text: "pay id", value: "pay_id" },
        { text: "BSB/Account No", value: "bsb" },
        { text: "Description", value: "description" },
      ],
      paymentsSummary: [],
    };
  },
  computed: {
    successRecords() {
      const records = [];
      if (
        this.fileValidateResultData &&
        this.fileValidateResultData.list_of_records.length > 0
      ) {
        this.fileValidateResultData.list_of_records.map((ele) => {
          if (ele.status == "S") {
            records.push(ele);
          }
        });
      }
      return records;
    },
    failureRecords() {
      const records = [];
      if (
        this.fileValidateResultData &&
        this.fileValidateResultData.list_of_records.length > 0
      ) {
        this.fileValidateResultData.list_of_records.map((ele) => {
          if (ele.status == "F") {
            records.push(ele);
          }
        });
      }
      return records;
    },
  },
  methods: {
    async downloadExcelsheet(type) {
      if (
        this.fileValidateResultData &&
        this.fileValidateResultData.list_of_records.length > 0
      ) {
        const temp = type == "F" ? this.failureRecords : this.successRecords;
        if (temp) {
          this.exportToExcel(this.headers, temp, "File Results");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.slot-icon {
  padding-top: 3px;
  font-size: 40px;
  border: 10px solid #ffe7d6;
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: #fdd1b2;
  margin: auto;
}

.fw-slot-icon {
  color: white;
}
.check-icon-success {
  padding-top: 10px;
  font-size: 35px;
  font-weight: 800;
  border: 12px solid rgb(252, 252, 167);
  height: 85px;
  width: 85px;
  border-radius: 50%;
  background-color: rgb(196, 196, 50);
  margin: auto;
}
.list-payout {
  overflow-y: scroll;
}
.fw-check-success {
  color: white;
}
.v-input--checkbox::v-deep {
  .v-label,
  .v-icon {
    margin-bottom: 0px !important;
  }
}
</style>
