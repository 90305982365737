<template>
  <v-card flat>
    <loading
      :active="loader"
      :is-full-page="true"
      :opacity="0.9"
      color="#ff6b00"
    />
    <v-card-title class="mt-0">
      <span></span>
      <v-btn @click="openDialog()" class="elevation-1" color="primary" small
        ><v-icon>mdi-plus </v-icon>Add</v-btn
      >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-dialog v-model="paymentdialog" max-width="700px">
      <v-card class="rounded-lg">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-divider class="mt-0"></v-divider>
        <v-card-text>
          <v-form v-model="valid" ref="busApplicant">
            <v-row>
              <v-col cols="12" md="6">
                <label class="mt-0"
                  >{{ $t("first_name_ind")
                  }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  :placeholder="$t('first_name')"
                  outlined
                  type="text"
                  hide-spin-buttons
                  required
                  dense
                  :rules="FieldRequired('First Name')"
                  v-model="add_Beneficiary.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label class="mt-0">{{ $t("middle_name") }}</label>
                <v-text-field
                  :placeholder="$t('middle_name_placeholder')"
                  outlined
                  type="text"
                  hide-spin-buttons
                  required
                  dense
                  v-model="add_Beneficiary.middle_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label class="mt-0"
                  >{{ $t("last_name_ind")
                  }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  :placeholder="$t('last_name')"
                  outlined
                  type="text"
                  hide-spin-buttons
                  required
                  dense
                  :rules="FieldRequired('Last Name')"
                  v-model="add_Beneficiary.last_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label
                  >{{ $t("emailID") }}<span style="color: #f00">*</span></label
                >
                <v-text-field
                  :placeholder="$t('emailID')"
                  outlined
                  required
                  dense
                  :rules="[...FieldRequired('Email Id'), ...emailValidations]"
                  v-model="add_Beneficiary.email_id"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label :description="$t('optional')">
                  {{ $t("phoneNumber") }}(+61 04XX XXX XXX)<span style="color: #f00">*</span>
                </label>
                <v-text-field
                  type="number"
                  hide-spin-buttons
                  :placeholder="$t('mobile_number_placeholder')"
                  outlined
                  required
                  dense
                  :rules="[...FieldRequired('Mobile Number'), ...mobileRules]"
                  v-model="add_Beneficiary.phone_number"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <label :description="$t('optional')">
                  {{ $t("Address") }}<span style="color: #f00">*</span>
                </label>
                <v-text-field
                  hide-spin-buttons
                  :placeholder="$t('Please Enter Address')"
                  outlined
                  required
                  dense
                  :rules="FieldRequired('Address')"
                  v-model="add_Beneficiary.address"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <label :description="$t('optional')">
                  Payment Method<span style="color: #f00">*</span>
                </label>
                <v-select
                  v-model="add_Beneficiary.payment_code"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :items="payidTypes"
                  placeholder="Select Payid Type"
                  outlined
                  type="number"
                  :rules="FieldRequired('Pay Id')"
                  hide-spin-buttons
                  required
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label class="mt-0"
                  >{{
                    add_Beneficiary.payment_code === "BSB"
                      ? "Account Number"
                      : "Pay Id"
                  }}<span style="color: #f00">*</span></label
                >
                <div class="d-flex">
                  <v-text-field
                    v-model="add_Beneficiary.bank_account_no"
                    placeholder="Please Enter Value"
                    outlined
                    :type="
                      add_Beneficiary.payment_code === 'PPHONE' ? 'number' : ''
                    "
                    hide-spin-buttons
                    :rules="returnValidation(add_Beneficiary.payment_code)"
                    required
                    dense
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="add_Beneficiary.payment_code === 'BSB'"
              >
                <label class="mt-0"
                  >BSB Number<span style="color: #f00">*</span></label
                >
                <div class="d-flex">
                  <v-text-field
                    v-model="add_Beneficiary.branch_code"
                    placeholder="Please Enter BSB Number"
                    outlined
                    type="number"
                    hide-spin-buttons
                    :rules="FieldRequired('BSB Number')"
                    required
                    dense
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="p-o m-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="paymentdialog = false" outlined>
            {{ $t("cancel_label") }}
          </v-btn>
          <v-btn @click="addAndUpdate()" class="elevation-0" color="primary">{{
            $t("Submit")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers" :items="beneficiary" class="elevation-1">
      <template v-slot:item.first_name="{ item }">
        <v-avatar
          size="34"
          class="rounded-circle me-3 font-weight-bold"
          :color="getColor()"
          dark
        >
          {{ returnAvatar(item.first_name) }}
        </v-avatar>
        <span>{{ returnFullName(item) }}</span>
      </template>
      <template v-slot:item.payment_code_data="{ item }">
        <v-chip
          small
          v-if="item.payment_code_data && item.payment_code_data.value"
          outlined
          color="primary"
          dark
        >
          {{
            item.payment_code_data && item.payment_code_data.value
              ? item.payment_code_data.value
              : ""
          }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="large" @click="editBeneficiary(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="large" @click="opendeleteDialog(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h6"
          >Are you sure you want to delete this Payout?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined small color="primary" @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn
            class="elevation-0"
            color="primary"
            small
            @click="deleteBeneficiary()"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { FieldRequired } from "@/utils/validation.js";
import Loading from "vue-loading-overlay";
import remitteService from "../../services/RemitteService/remitteService.js";
import commonService from "../../services/CommonService/commonService.js";
import alerts from "@/mixins/alerts";
import { validations } from "../../utils/validation.js";
const { emailValidations, mobileRules } = validations;
export default {
  mixins: [alerts],
  data() {
    return {
      emailValidations,
      mobileRules,
      search: "",
      loader: false,
      FieldRequired,
      update_id: null,
      deleteId: null,
      paymentdialog: false,
      payment_type: "BSB/Account Number",
      payidTypes: [],
      add_Beneficiary: {
        first_name: "",
        middle_name: null,
        last_name: "",
        email_id: "",
        phone_number: "",
        payment_code: "PEMAIL",
        bank_account_no: "",
        address: "",
        branch_code: "",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        { text: "Email", value: "email_id" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Payment Method", value: "payment_code_data" },
        { text: "Pay ID/Account Number", value: "bank_account_no" },
        { text: "Created Date", value: "created_date" },
        { text: "Actions", value: "actions" },
      ],
      beneficiary: [],
      isTitle: false,
      dialogDelete: false,
    };
  },
  computed: {
    dialogTitle() {
      return this.isTitle ? this.$t("Update Payout") : this.$t("Add Payout");
    },
  },
  components: {
    Loading,
  },
  mounted() {
    console.log(`the component is now mounted.`);
    this.getBeneficiary();
    this.getpayidMethods();
  },
  methods: {
    opendeleteDialog(item) {
      this.dialogDelete = true;
      this.deleteId = item.uuid;
    },
    returnValidation(val) {
      if (val === "PEMAIL") {
        return [...this.FieldRequired("value"), ...this.emailValidations];
      } else if (val === "PPHONE") {
        return [...this.FieldRequired("value"), ...this.mobileRules];
      } else {
        return [...this.FieldRequired("value")];
      }
    },
    returnFullName(item) {
      if (item.middle_name) {
        return item.first_name + " " + item.middle_name + " " + item.last_name;
      } else {
        return item.first_name + " " + item.last_name;
      }
    },
    openDialog() {
      this.paymentdialog = true;
      this.isTitle = false;
      this.$refs.busApplicant.reset();
      this.add_Beneficiary.payment_code = "PEMAIL";
    },
    async addBeneficiary() {
      console.log("add_beneficiary", this.add_Beneficiary);

      if (this.$refs.busApplicant.validate()) {
        try {
          this.loader = true;
          const response = await remitteService.addBeneficiary(
            this.add_Beneficiary
          );
          if (response.data.status_code === 200 && response.data) {
            this.paymentdialog = false;
            this.loader = false;
            console.log("response", response);
            this.showAlertSuccess(response.data.message);
            this.getBeneficiary();
          } else {
            this.showAlertError(response.data.message);
            this.loader = false;
          }
        } catch (error) {
          this.loader = false;
          console.log(error);
        }
      }
    },
    async getpayidMethods() {
      try {
        const response = await commonService.getParamterDropdown("DPTY");
        console.log("getpayidMethods", response.data);
        if (response.data.status_code === 200 && response.data) {
          this.payidTypes = response.data.data.map((ele) => {
            return {
              text: ele.value,
              value: ele.value_code,
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getBeneficiary() {
      const params = {
        skip: 0,
        limit: 30000,
      };
      try {
        this.loader = true;
        const response = await remitteService.getBeneficiary(params);
        console.log("response", response.data.message);
        if (response.data.status_code === 200 && response.data) {
          this.loader = false;
          this.beneficiary = response.data.data;
          console.log(this.beneficiary);
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        this.beneficiary = [];
        console.log(error);
      }
    },
    editBeneficiary(item) {
      this.isTitle = true;
      this.add_Beneficiary = { ...item }; // Update form with beneficiary data
      this.add_Beneficiary.payment_code = item.payment_code_data.value_code;
      this.paymentdialog = true;
      this.update_id = item.uuid;
    },

    async updateBenficiary(id) {
      console.log(id);
      if (this.$refs.busApplicant.validate()) {
        try {
          const response = await remitteService.updateBeneficiary(
            this.add_Beneficiary,
            id
          );
          if (response.data.status_code === 200 && response.data) {
            this.paymentdialog = false;
            console.log("response", response);
            this.showAlertSuccess(response.data.message);
            this.getBeneficiary();
          }else{
            this.showAlertError(response.data.message);
            this.paymentdialog = false;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    addAndUpdate() {
      if (this.isTitle) {
        this.updateBenficiary(this.update_id);
      } else {
        this.addBeneficiary();
      }
    },
    async deleteBeneficiary() {
      const response = await remitteService.deleteBeneficiary(this.deleteId);
      try {
        this.loader = true;
        if (response.data && response.data.status_code === 200) {
          console.log(response);
          this.loader = false;
          this.dialogDelete = false;
          console.log("response", response);
          this.showAlertSuccess(response.data.message);
          this.getBeneficiary();
        } else {
          this.showAlertError(response.data.message);
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },

    returnAvatar(name) {
      const words = name.split(" ");
      // Map each word to its first character and join them
      const initials = words.map((word) => word.charAt(0)).join("");
      // Return the initials in uppercase
      return initials[0].toUpperCase();
    },
    getpayColor(status) {
      if (status == "Pay Id") return "primary";
      else return "green";
    },
    getColor() {
      // Generate random values for RGB, ensuring they are between 127 and 255
      const r = Math.floor(Math.random() * 128) + 127;
      const g = Math.floor(Math.random() * 128) + 127;
      const b = Math.floor(Math.random() * 128) + 127;

      // Convert the RGB values to a hexadecimal string
      const hexColor = `#${r.toString(16).padStart(2, "0")}${g
        .toString(16)
        .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

      return hexColor.toUpperCase();
    },
  },
};
</script>
<style lang="scss" scoped>
.width-select {
  max-width: 34% !important;
}
</style>
